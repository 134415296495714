import { lazy } from 'react';
import { Route, RouteComponentProps, Switch } from 'react-router-dom';

const EnquiryList = lazy(() => import('./EnquiryList'));
const EnquiryDetail = lazy(() => import('./EnquiryDetail'));
const EnquiryUpdate = lazy(() => import('./EnquiryUpdate'));

const Enquiry = ({ match }: RouteComponentProps) => (
  <Switch>
    <Route exact={true} path={`${match.url}/:tab?`} component={EnquiryList} />
    <Route
      exact={true}
      path={`${match.url}/detail/:id`}
      component={EnquiryDetail}
    />
    <Route
      exact={true}
      path={`${match.url}/update/:id`}
      component={EnquiryUpdate}
    />
  </Switch>
);

export default Enquiry;
