import { DownOutlined } from '@ant-design/icons';
import { gql, useMutation } from '@apollo/client';
import { Button, Dropdown, Menu } from 'antd';
import jwtDecode from 'jwt-decode';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { getAccessToken, setAccessToken } from './accessToken';

const LOGOUT = gql`
  mutation Logout {
    logout
  }
`;

const SButton = styled(Button)`
  align-self: center;
  border: none;
`;

const Logout = () => {
  const { t } = useTranslation();
  const [logout, { loading }] = useMutation(LOGOUT);
  const history = useHistory();
  const token = getAccessToken();

  const onLogout = async () => {
    await logout();
    setAccessToken('');
    history.push('/login');
  };

  return (
    <div className="w-24 flex justify-end items-center pt-4 md:pt-0">
      <Dropdown
        trigger={['click']}
        overlay={
          <Menu>
            <Menu.Item key="1" onClick={onLogout}>
              {t('common.logout')}
            </Menu.Item>
          </Menu>
        }
      >
        <SButton block={true} loading={loading}>
          {(jwtDecode(token) as any).username} <DownOutlined />
        </SButton>
      </Dropdown>
    </div>
  );
};

export default Logout;
