import { Spin } from 'antd';
import { lazy, Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import { Route, Switch } from 'react-router-dom';

import Enquiry from './modules/enquiry/Enquiry';
import PrivateRoute from './security/PrivateRoute';
const Login = lazy(() => import('./security/Login'));
const Home = lazy(() => import('./modules/home/components/Calendar'));
const Driver = lazy(() => import('./modules/driver/Driver'));
const Client = lazy(() => import('./modules/client/Client'));
const Child = lazy(() => import('./modules/child/Child'));
const School = lazy(() => import('./modules/school/School'));
const ClientRideCreate = lazy(() => import('./modules/ride/ClientRideCreate'));
const RideCreate = lazy(() => import('./modules/ride/RideCreate'));
const RideUpdate = lazy(() => import('./modules/ride/RideUpdate'));
const RideCopy = lazy(() => import('./modules/ride/RideCopy'));
const Registration = lazy(() => import('./modules/registration/Registration'));

const ClientPortal = lazy(() => import('./client/Client'));

const App = () => {
  const { t } = useTranslation();

  return (
    <>
      <Suspense
        fallback={
          <Spin
            tip={t('common.loading')}
            style={{ width: '100%', margin: 'auto', paddingTop: '4em' }}
          />
        }
      >
        <Switch>
          {/* Klient portal */}
          <Route path="/klient" component={ClientPortal} />
          {/* Private portal */}
          <Route exact={true} path="/login" component={Login} />
          <PrivateRoute
            exact={true}
            path="/ride/:id/:date"
            component={RideUpdate}
          />
          <PrivateRoute path="/driver" component={Driver} />
          <PrivateRoute path="/client" component={Client} />
          <PrivateRoute path="/child" component={Child} />
          <PrivateRoute path="/school" component={School} />
          <PrivateRoute path="/enquiry" component={Enquiry} />
          <PrivateRoute path="/registration" component={Registration} />
          <PrivateRoute exact={true} path="/:date?" component={Home} />
          <PrivateRoute
            exact={true}
            path="/:date/client-ride"
            component={ClientRideCreate}
          />
          <PrivateRoute
            exact={true}
            path="/:date/ride"
            component={RideCreate}
          />
          <PrivateRoute
            exact={true}
            path="/:date/copy/:id"
            component={RideCopy}
          />
          <PrivateRoute path="*" render={() => <div>nenalezeno</div>} />
        </Switch>
      </Suspense>
    </>
  );
};

export default App;
