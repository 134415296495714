import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import csCZ from './dictionary/csCZ';
import enGB from './dictionary/enGB';

export enum Languages {
  CZ = 'cs-CZ',
  EN = 'en-GB',
}

i18n.use(initReactI18next).init({
  resources: {
    [Languages.CZ]: {
      translation: csCZ,
    },
    [Languages.EN]: {
      translation: enGB,
    },
  },
  lng: Languages.CZ,
  fallbackLng: Languages.CZ,
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
