import {
  ApolloClient,
  ApolloLink,
  createHttpLink,
  InMemoryCache,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { TokenRefreshLink } from 'apollo-link-token-refresh';
import jwtDecode from 'jwt-decode';

import { getAccessToken, setAccessToken } from './security/accessToken';

export const getClient = () => {
  const refreshTokenUrl = `${process.env.REACT_APP_API_URI}/pandaride-portal/refresh_token`;

  const authLink = setContext((_, { headers }) => {
    const token = getAccessToken();
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : '',
      },
    };
  });

  const httpLink = createHttpLink({
    uri: `${process.env.REACT_APP_API_URI}/pandaride-portal/graphql`,
    credentials: 'include',
  });

  const link = ApolloLink.from([
    new TokenRefreshLink({
      accessTokenField: 'accessToken',
      isTokenValidOrUndefined: () => {
        const token = getAccessToken();
        if (!token) {
          return true;
        }
        try {
          const decoded: any = jwtDecode(token);
          return Date.now() < decoded.exp * 1000;
        } catch {
          return false;
        }
      },
      handleFetch: (accessToken: string) => {
        setAccessToken(accessToken);
      },
      fetchAccessToken: () => {
        return fetch(refreshTokenUrl, {
          method: 'POST',
          credentials: 'include',
        });
      },
    }) as any,
    authLink,
    httpLink,
  ]);

  return new ApolloClient({
    link,
    cache: new InMemoryCache(),
  });
};
