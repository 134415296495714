const dictionary = {
  enquiry: {
    scheduleEmail:
      'Dobrý den,<br/>vaše jízda byla naplánována.<br/><br/><strong>Řidič:</strong><br/>Jméno: {{ name }} {{ surname }}<br/>Telefon: {{ phone }}<br/>Vozidlo: {{ model }}',
    rescheduleEmail:
      'Dobrý den,<br/>vaše jízda byla přeplánována.<br/><br/><strong>Řidič:</strong><br/>Jméno: {{ name }} {{ surname }}<br/>Telefon: {{ phone }}<br/>Vozidlo: {{ model }}',
  },
  validation: {
    required: 'Toto pole je povinné',
    gdprRequired: 'Musíte souhlasit s GDPR.',
    generalTermsRequired: 'Musíte souhlasit s obchodními podmínkami.',
    addChild: 'Musíte přidat alespoň jedno dítě',
    addContact: 'Musíte přidat alespoň jeden kontakt',
  },
  ares: {
    error: 'Společnost nenalezena',
  },
  registration: {
    title: {
      default: 'Registrace klienta',
      isp: 'Registrace klienta k dopravě do školy',
    },
    success: {
      title: 'Děkujeme! Registrace do PandaRide je dokončena.',
      next: 'Nová registrace',
    },
    steps: {
      parent: 'Rodič',
      children: 'Údaje o dětech',
      contacts: 'Záložní kontakty',
      summary: 'Souhrn',
    },
    parent: {
      name: 'Jméno rodiče',
      surname: 'Příjmení rodiče',
      address: 'Ulice a č.p.',
      city: 'Obec',
      cin: 'IČO',
      tin: 'DIČ',
      phone: 'Telefon',
      email: 'Email',
      is_invoicing_company: 'Fakturuji na firmu',
      company: 'Společnost',
      load_from_ares: 'Načíst z ARES',
      submit: 'Pokračovat',
    },
    children: {
      child: 'Dítě',
      photo: 'Foto',
      name: 'Jméno',
      surname: 'Příjmení',
      dob: 'Datum narození',
      notes: 'Poznámky',
      add: 'Další dítě',
      submit: 'Pokračovat',
      notesPlaceholder:
        'Jméno učitele/trenéra informace ke zdravotnímu stavu. (Alergie, nemoci, atd.)',
      grade: 'Třída',
      teacher: 'Učitel / Učitelka',
    },
    contacts: {
      contact: 'Záložní kontakt',
      name: 'Jméno',
      surname: 'Příjmení',
      phone: 'Telefon',
      role: 'Vztah k dítěti',
      add: 'Přidat kontakt',
      submit: 'Pokračovat',
    },
    summary: {
      agreeWith: 'Souhlasím s',
      agreedGdpr: 'Souhlasím s GDPR',
      generalTerms: 'obchodními podmínkami',
      submit: 'Dokončit registraci',
    },
  },
  event: {
    title: 'Událost',
    message: {
      createSuccess: 'Událost byla vytvořena.',
      createError: 'Při vytváření události nastala chyba.',
      updateSuccess: 'Událost byla upravena.',
      updateError: 'Při úpravě události nastala chyba.',
      deleteSuccess: 'Událost byla smazána.',
      deleteError: 'Při mazání události nastala chyba.',
    },
  },
  client: {
    clients: 'Klienti',
    billingInformation: 'Fakturační údaje',
    newClient: 'Nový klient',
    editClient: 'Úprava klienta',
    noClientSelected: 'Vyberte alespoň jednoho klienta',
    message: {
      createError: 'Při vytváření klienta nastala chyba',
      createSuccess: 'Klient {{ name }} byl vytvořen',
      deleteError: 'Při mazání klienta nastala chyba',
      deleteSuccess: 'Klient {{ name }} byl smazán',
      updateError: 'Při úpravě klienta nastala chyba',
      updateSuccess: 'Klient {{ name }} byl upraven.',
    },
  },
  school: {
    message: {
      createError: 'Při vytváření školy nastala chyba',
      createSuccess: 'Škola byla vytvořena',
      deleteError: 'Při mazání školy nastala chyba',
      deleteSuccess: 'Škola byla smazána',
      updateError: 'Při úpravě školy nastala chyba',
      updateSuccess: 'Škola byla upravena.',
    },
  },
  driver: {
    newDriver: 'Nový řidič',
    message: {
      deleteSuccess: 'Řidič byl smazán',
      createSuccess: 'Řidič byl vytvořen',
      updateSuccess: 'Řidič byl upraven',
      updateError: 'Při úpravě řidiče nastala chyba',
      createError: 'Při vytváření řidiče nastala chyba',
      deleteError: 'Při mazání řidiče nastala chyba',
    },
  },
  settings: {
    title: 'Nastavení',
    editLocationSettings: 'Editace polohy',
    locationSettings: 'Nastavení polohy',
    message: {
      createSuccess: 'Nastavení bylo úspěšně uloženo',
      deleteSuccess: 'Nastavení bylo úspěšně smazáno',
      updateSuccess: 'Nastavení bylo úspěšně upraveno',
      createError: 'Při vytváření nastavení nastala chyba',
      deleteError: 'Při mazání nastavení nastala chyba',
      updateError: 'Při úpravě nastavení nastala chyba',
    },
  },
  ride: {
    removeSingleRide: 'Pouze tuto událost',
    removeRecurringRide: 'Všechna opakování události',
    removeRecurringRideFromToday: 'Všechna opakování události ode dneška',
    sendEmail: 'Odeslat email',
    createRide: 'Nová jizda',
    client: 'Jméno klienta',
    location: 'Poloha',
    message: {
      createSuccess: 'Jízda byla úspěšně uložena',
      deleteSuccess: 'Jízda byla úspěšně smazána',
      updateSuccess: 'Jízda byla úspěšně upravena',
      createError: 'Při vytváření jízdy nastala chyba',
      deleteError: 'Při mazání jízdy nastala chyba',
      updateError: 'Při úpravě jízdy nastala chyba',
      sendSuccess: 'Emaily byly úspěšně odeslány.',
      sendError: 'Emaily se nepovedlo odeslat.',
    },
  },
  common: {
    logout: 'Odhlásit',
    czk: 'CZK',
    storno: 'CZK (storno)',
    yes: 'Ano',
    no: 'Ne',
    loading: 'Načítání',
    optimify: 'Optimify ©2020',
    save: 'Uložit',
    ride: 'Jízda',
    delete: 'Smazat',
    deleteConfirm: 'Opravdu chcete položku smazat?',
  },
  checkbox: {
    completed: 'Provedená jízda',
    unrealized: 'Neuskutečněná jízda',
    recurring: 'Pravidelná jízda',
  },
  error: {
    internalServerError: 'Něco se pokazilo',
  },
  header: {
    calendar: 'Kalendář',
    clients: 'Klienti',
    children: 'Děti',
    school: 'Školy',
    drivers: 'Řidiči',
    settings: 'Nastavení',
    event: 'Události',
  },
  input: {
    billingName: 'Jméno / firma',
    billingAddress: 'Adresa',
    phone: 'Telefon',
    cancellation: 'Storno',
    crn: 'IČ',
    vatRegNum: 'DIČ',
    repeatDays: 'Opakovat ve dnech',
    rideType: 'Typ jízdy',
    email: 'E-mail',
    date: 'Datum',
    address: 'Bydliště',
    birthDate: 'Datum narození',
    contact: 'Kontakt',
    childName: 'Jméno dítěte',
    childNameRequired: 'Zadejte jméno dítěte!',
    monthPayment: 'Měsíční platba / paušál',
    name: 'Jméno',
    nameRequired: 'Zadejte jméno!',
    note: 'Popis',
    notes: 'Poznámky',
    parentsContact: 'Kontakt na rodiče',
    parentsName: 'Jméno rodiče',
    parentsNameRequired: 'Zadejte jméno rodiče!',
    seatType: 'Typ sedačky',
    rideName: 'Název jízdy',
    rideTime: 'Čas od - do',
    destination: 'Cíl cesty',
    start: 'Počátek cesty',
    passenger: 'Cestující',
    driver: 'Řidič',
    driverContact: 'Kontakt na řidiče',
    otherDestination: 'Přes',
    required: 'Poviné pole',
    ridePrice: 'Cena cesty',
    recurringRide: 'Nastavit opakování jizdy',
    recurringRideEveryWorkingDay: 'Každý pracovní den',
    recurringRideSelectedDays: 'Každý zvolený den',
    recuringRideSelectFromCalendar: 'Vybrat z kalendáře',
    key: 'Klíč',
    location: 'Místo',
    eventDescription: 'Popis události',
    image: {
      label: 'Foto',
      text: 'Vyberte obrázek',
    },
  },
};

export default dictionary;
