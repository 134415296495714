import './tailwind.css';
import 'antd/dist/antd.min.css';
import 'moment/locale/cs';
import './translation/i18n';

import { ApolloProvider } from '@apollo/client';
import { ConfigProvider } from 'antd';
import locale from 'antd/lib/locale/cs_CZ';
import moment from 'moment';
import ReactDOM from 'react-dom';
import { HashRouter } from 'react-router-dom';

import App from './App';
import { GlobalStyle } from './constants/theme';
import { getClient } from './getClient';
import RefreshToken from './security/RefreshToken';

moment.locale('cs');

const refreshTokenUrl = `${process.env.REACT_APP_API_URI}/pandaride-portal/refresh_token`;

ReactDOM.render(
  <ApolloProvider client={getClient()}>
    <HashRouter>
      <ConfigProvider locale={locale}>
        <RefreshToken url={refreshTokenUrl}>
          <GlobalStyle />
          <App />
        </RefreshToken>
      </ConfigProvider>
    </HashRouter>
  </ApolloProvider>,
  document.getElementById('root'),
);
