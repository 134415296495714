import { Menu, Space } from 'antd';
import {
  Link,
  Redirect,
  Route,
  RouteProps,
  useHistory,
  useLocation,
} from 'react-router-dom';

import logo from '../logo.svg';
import { getAccessToken } from './accessToken';
import Logout from './Logout';

const PrivateRoute = ({ component: Component, ...rest }: RouteProps) => {
  const token = getAccessToken();
  const { pathname } = useLocation();
  const history = useHistory();

  return (
    <Route
      {...rest}
      render={(props) =>
        token ? (
          <>
            {token && (
              <div className="flex flex-col md:flex-row justify-center items-center p-2">
                <img
                  className="w-32 md:w-36 p-2"
                  onClick={() => history.push('/')}
                  src={logo}
                  alt="PANDA ride"
                />
                <Menu
                  className="w-full"
                  theme="light"
                  mode="horizontal"
                  selectedKeys={[pathname]}
                >
                  <Menu.Item key="/">
                    <Link to="/">Kalendář</Link>
                  </Menu.Item>
                  <Menu.Item key="/client">
                    <Link to="/client">Klienti</Link>
                  </Menu.Item>
                  <Menu.Item key="/child">
                    <Link to="/child">Děti</Link>
                  </Menu.Item>
                  <Menu.Item key="/driver">
                    <Link to="/driver">Řidiči</Link>
                  </Menu.Item>
                  <Menu.Item key="/school">
                    <Link to="/school">Školy</Link>
                  </Menu.Item>
                  <Menu.Item key="/enquiry">
                    <Link to="/enquiry">
                      <Space>
                        Poptávky
                        {/*<EnquiryCountBadge />*/}
                      </Space>
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="/registration">
                    <Link to="/registration">Registrace</Link>
                  </Menu.Item>
                </Menu>
                <Logout />
              </div>
            )}
            {/*eslint-disable-next-line @typescript-eslint/ban-ts-comment*/}
            {/*@ts-ignore*/}
            <Component {...props} />
          </>
        ) : (
          <Redirect to="/login" />
        )
      }
    />
  );
};

export default PrivateRoute;
