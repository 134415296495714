const dictionary = {
  enquiry: {
    scheduleEmail:
      'Hi,<br/>your ride has been scheduled.<br/><br/><strong>Driver:</strong><br/>Name: {{ name }} {{ surname }}<br/>Phone: {{ phone }}<br/>Car: {{ model }}',
    rescheduleEmail:
      'Hi,<br/>your ride has been rescheduled.<br/><br/><strong>Driver:</strong><br/>Name: {{ name }} {{ surname }}<br/>Phone: {{ phone }}<br/>Car: {{ model }}',
  },
  validation: {
    required: 'This field is required',
    gdprRequired: 'You have to agree with GDPR.',
    generalTermsRequired: 'You have to agree with general terms.',
    addChild: 'You have to add at least one child',
    addContact: 'You have to add at least one contact',
  },
  ares: {
    error: 'Company was not found',
  },
  registration: {
    title: {
      default: 'Client registration',
      isp: 'Client registration for transport',
    },
    success: {
      title: 'Thanks mate! Your registration to PandaRide is complete.',
      next: 'New registration',
    },
    steps: {
      parent: 'Parent',
      children: 'Children details',
      contacts: 'Emergency contacts',
      summary: 'Summary',
    },
    parent: {
      name: 'Parent name',
      surname: 'Parent surname',
      address: 'Street',
      city: 'City',
      cin: 'CIN - company identification number',
      tin: 'TIN - tax identification number',
      phone: 'Phone',
      email: 'Email',
      is_invoicing_company: 'Invoice to company',
      company: 'Company name',
      load_from_ares: 'Load from ARES',
      submit: 'Continue',
    },
    children: {
      child: 'Child',
      photo: 'Photo',
      name: 'Name',
      surname: 'Surname',
      dob: 'Date of birth',
      notes: 'Notes',
      add: 'Add child',
      submit: 'Continue',
      notesPlaceholder:
        'Class,Name of teacher/coach Health information (Allergies, illnesses, etc.)',
      grade: 'Grade',
      teacher: 'Teacher',
    },
    contacts: {
      contact: 'Emergency contact',
      name: 'Name',
      surname: 'Surname',
      phone: 'Phone',
      role: 'Role',
      add: 'Add contact',
      submit: 'Continue',
    },
    summary: {
      agreedGdpr: 'I agree with GDPR',
      submit: 'Finish registration',
      agreeWith: 'I agree with',
      generalTerms: 'general terms',
    },
  },
};

export default dictionary;
