import { Spin } from 'antd';
import * as React from 'react';

import { setAccessToken } from './accessToken';

const RefreshToken: React.FC<{ url: string }> = ({ children, url }: any) => {
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    fetch(url, {
      method: 'POST',
      credentials: 'include',
    })
      .then(async (response) => {
        const { accessToken } = await response.json();
        setAccessToken(accessToken);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [url]);

  if (loading) {
    return <Spin />;
  }

  return <>{children}</>;
};

export default RefreshToken;
